import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export default styled(ToastContainer)`
  .Toastify__toast--success {
    background: #07bc0c;
  }

  .Toastify__toast--warning {
    background: #f1c40f;
  }

  .Toastify__toast--error {
    background: ${(props) => props.theme.colors.vodafoneRed};
  }

  .Toastify__toast-body {
    font-family: Vodafone Rg;
    font-size: 18px;
  }

  .Toastify__close-button > svg {
    color: #fff;
    fill: #fff;
  }

  .Toastify__close-button--light {
    color: #fff;
    opacity: 0.8;
  }

  --toastify-text-color-light: #fff;
  --toastify-color-progress-success: #fff;
  --toastify-color-progress-warning: #fff;
  --toastify-color-progress-error: #fff;
`;
