import React, { useEffect } from 'react';
import { signOut } from '@aws-amplify/auth';
import axios from 'axios';
import moment from 'moment';
import { useLocation } from 'react-router';
import { datadogRum } from '@datadog/browser-rum';
import { parseJwt } from '../../services/authListener';
import useBroadcastChannel from '../../services/authListener/useBroadcastChannel';

const AuthVerify: React.FC<any> = ({
  userState,
  setUserState,
  setIsToastVisible
}) => {
  let decodedJwt: any;
  const location = useLocation();
  const { message, sendMessage }: any = useBroadcastChannel('auth_channel');

  const handleSignOut = async () => {
    await signOut();
    setUserState({ authState: 'signedOut', user: undefined });
  };

  useEffect(() => {
    sessionStorage.setItem('reloaded', 'yes');
  }, []);

  useEffect(() => {
    if (userState.authState === 'signedIn' && userState.user) {
      const { sub, name, email } = userState?.user?.idToken?.payload;
      datadogRum.setUser({
        id: sub,
        name,
        email
      });
    } else {
      datadogRum.clearUser();
    }
  }, [userState]);

  useEffect(() => {
    if (message && message.type === 'signOut') {
      handleSignOut();
      setUserState({ authState: 'signedOut', user: undefined });
    }
  }, [message]);

  if (sessionStorage.getItem('reloaded') === null) {
    localStorage.clear();
  }

  if (userState.user) {
    const accessToken = userState?.user?.accessToken?.toString();
    decodedJwt = parseJwt(accessToken);
  }

  // log out user on any api request, when access token expires
  axios.interceptors.request.use((request) => {
    if (decodedJwt && decodedJwt.exp * 1000 < moment().valueOf()) {
      setIsToastVisible(false);
      handleSignOut();
      sendMessage({ type: 'signOut' });
    } else if (userState.user) {
      // Add idToken on every req
      if (
        !request.url?.match(
          '^https://cavendishengine-[a-z0-9]+.s3.amazonaws.com/.*'
        )
      ) {
        request.headers.Authorization = userState?.user?.idToken?.toString();
      }
    }

    return request;
  });

  // log out user on tab change or refresh, when access token expires
  useEffect(() => {
    if (
      userState.user &&
      decodedJwt &&
      decodedJwt.exp * 1000 < moment().valueOf()
    ) {
      handleSignOut();
      sendMessage({ type: 'signOut' });
    }
  }, [location.pathname, userState]);

  return <></>;
};

export default AuthVerify;
