import React, { useState, SyntheticEvent, useEffect } from 'react';
import { Container, SelectChangeEvent } from '@mui/material';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router';

import StyledSearchBar from './styles';
import CustomButton from '../CustomButton';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import environment from '../../environment';
import LoadingScreen from '../LoadingScreen';
import ControlledInput from '../ControlledInput';
import ControlledSelect from '../ControlledSelect';

export interface ISearchBarProps {
  searchOptions: IGenericEntry[];
  buttonTitle: string;
  path: string;
  handleSuccess: CallableFunction;
  clearData: CallableFunction;
}

const SearchBar: React.FC<ISearchBarProps> = ({
  searchOptions,
  buttonTitle,
  path,
  handleSuccess,
  clearData
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchOption, setSearchOption] = useState<IGenericEntry>(
    searchOptions[0]
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const productCode = 'productCode';

  const getPaginatedElements = async (
    option: string,
    value: string,
    lastEvaluatedOrder: string | undefined = undefined,
    results: Array<string> = []
  ) => {
    try {
      const res = await axios.get(
        `${environment.apiPath}${path}?${option}=${value}`,
        {
          params: {
            pageSize: 500,
            lastEvaluatedOrder
          },
          ...environment.params
        }
      );

      if (!res.data.length) {
        handleSuccess(results);
        setLoading(false);
      } else {
        results.push(...res.data);
        await getPaginatedElements(
          option,
          value,
          res.data[res.data.length - 1].orderId,
          results
        );
      }
    } catch (err: AxiosError | any) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const getElements = async (option: string, value: string) => {
    try {
      if (option === 'customerId') {
        await getPaginatedElements(option, value);
      } else {
        const res = await axios.get(
          `${environment.apiPath}${path}?${option}=${value}`,
          {
            ...environment.params
          }
        );
        handleSuccess(res.data);
        setLoading(false);
      }
    } catch (err: AxiosError | any) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (location.search) {
        const searchElements = location.search.split(/[?&=]/);
        const option = searchOptions.find(
          (opt: IGenericEntry) => opt.code === searchElements[1]
        );

        if (option) {
          setSearchOption(option);
        }
        clearData();
        setLoading(true);

        await getElements(searchElements[1], searchElements[2]);
      }
    };

    fetchData();
  }, [location]);

  const handleSearchOptionChange = (event: SelectChangeEvent<any>) => {
    setSearchValue('');
    setSearchOption(event.target.value);
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const isProductCode: boolean = searchOption.code === productCode;
    const regex = /^[0-9,]*$/;

    setSearchValue(
      isProductCode ? (regex.test(value) ? value : searchValue) : value
    );
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    navigate(`${location.pathname}?${searchOption.code}=${searchValue}`);
  };

  return (
    <>
      <StyledSearchBar>
        <Container fixed>
          <form noValidate autoComplete='off' onSubmit={handleSubmit}>
            <ControlledSelect
              id='search-option'
              value={searchOption}
              handleChange={handleSearchOptionChange}
              label='Search option'
              options={searchOptions}
              classes='label--w-20'
            />
            <ControlledInput
              id='search-value'
              placeholder={`Type ${searchOption.value.toLowerCase()} here`}
              value={searchValue}
              handleChange={handleSearchValueChange}
              label={searchOption.value}
              type='text'
              classes='label--w-60'
            />
            <CustomButton
              type='submit'
              disabled={!searchValue}
              classes='btn--w-20'
              title={buttonTitle}
            />
          </form>
        </Container>
      </StyledSearchBar>
      {loading && <LoadingScreen />}
    </>
  );
};

export default SearchBar;
