import React from 'react';
import {
  DateTimeValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import StyledDateTimePicker from './styles';
import Label from '../Label';

interface ICustomDatePickerProps {
  id: string;
  value: Date | null;
  handleChange: (
    value: unknown,
    context: PickerChangeHandlerContext<DateTimeValidationError>
  ) => void;
  label: string;
  classes?: string;
  disabled?: boolean;
  minDate?: any;
  helperText?: string;
  onError?: any;
}

const CustomDatePicker: React.FC<ICustomDatePickerProps> = ({
  id,
  value,
  handleChange,
  label,
  classes,
  disabled,
  minDate,
  helperText,
  onError
}) => (
  <Label
    id={id}
    control={
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledDateTimePicker
          value={value}
          slotProps={{
            textField: {
              helperText: helperText,
              placeholder: 'dd/mm/yyyy HH:MM'
            }
          }}
          defaultValue={minDate}
          format='dd/MM/yyyy HH:mm'
          onChange={handleChange}
          disabled={disabled}
          minDateTime={minDate}
          onError={onError}
        />
      </LocalizationProvider>
    }
    label={label}
    classes={classes}
  />
);

CustomDatePicker.defaultProps = {
  classes: '',
  disabled: false,
  minDate: undefined,
  helperText: '',
  onError: null
};

export default CustomDatePicker;
