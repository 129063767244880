import { ResourcesConfig } from 'aws-amplify';
import environment from '../environment';

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: environment.userPoolId as string,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: environment.userPoolClientId as string,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      // mandatorySignIn: false,

      loginWith: {
        oauth: {
          domain: environment.cognitoDomain as string,

          scopes: [
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin'
          ],

          redirectSignIn: [environment.redirectSignIn as string],

          redirectSignOut: [environment.redirectSignOut as string],

          responseType: 'code' // or token

          // optional, for Cognito hosted ui specified options
          // options: {
          //   // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
          //   AdvancedSecurityDataCollectionFlag: true
          // }
        }
      }
    }

    /* // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: '.yourdomain.com',
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true
    },

    // OPTIONAL - customized storage object
    storage: new MyStorage(),

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH' */
  }
};
