import { useEffect, useState } from 'react';

const useBroadcastChannel = (channelName: any) => {
  const [message, setMessage] = useState(null);
  const channel = new BroadcastChannel(channelName);

  useEffect(() => {
    const handleMessage = (event: any) => {
      setMessage(event.data);
    };

    channel.onmessage = handleMessage;

    return () => {
      channel.close();
    };
  }, [channel]);

  const sendMessage = (msg: any) => {
    channel.postMessage(msg);
  };

  return { message, sendMessage };
};

export default useBroadcastChannel;
