import styled from 'styled-components';

export default styled('div')<{ expandedChip: boolean }>`
  position: relative;

  .autocomplete__input {
    display: flex;
    align-items: flex-end;

    .MuiButton-endIcon {
      margin: 0;
      justify-content: center;
    }

    .expand-icon.open {
      transform: translateZ(0) rotate(0.5turn);
    }
  }

  .autocomplete__dropdown {
    position: absolute;
    overflow: hidden;
    width: 100%;
    z-index: 999;
    background-color: ${(props) => props.theme.colors.lightGrey};
    box-shadow:
      2px 1px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    overflow-y: auto;
    max-height: 325px;
    li {
      color: ${(props) => props.theme.colors.darkGrey};
      font-family: Vodafone Rg;
      font-size: 18px;
      line-height: 26px;
      padding: 10px;
    }
  }

  .autocomplete__searchterms-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: ${(props: { expandedChip: boolean }) =>
      props.expandedChip ? '100%' : '100px'};
    overflow: ${(props: { expandedChip: boolean }) =>
      props.expandedChip ? 'visible' : 'hidden'};

    .MuiChip-root {
      font-family: Vodafone Lt;
      font-size: 18px;
      padding: 20px 7px;
      border-radius: 20px;
      margin: 10px 10px 0 0;
      background-color: #e0e0e0;
    }
  }
  p {
    margin: 0px;
  }
  .view-more-button {
    &.MuiButton-root {
      display: flex;
      justify-content: center;
      padding-top: 10px;

      img {
        margin-left: 20px;
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        -ms-transition: -ms-transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        transform: rotate(
          ${(props: { expandedChip: boolean }) =>
            props.expandedChip ? 180 : 0}deg
        );
      }
    }
  }
`;
