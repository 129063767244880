import styled from 'styled-components';

export default styled('div')<{ expanded: boolean; hideExpand?: boolean }>`
  display: flex;

  &:nth-child(even) {
    .expand-right {
      border-left: 2px solid ${(props) => props.theme.colors.darkGrey};
    }
  }

  &:nth-child(odd) {
    .expand-right {
      border-left: 2px solid ${(props) => props.theme.colors.vodafoneRed};
    }
  }

  .expand-right {
    display: flex;
    justify-content: space-between;
    width: ${(props: { hideExpand?: boolean }) =>
      props.hideExpand ? '100%' : 'calc(70% - 10px)'};
    height: fit-content;
    margin: 5px 5px 5px 0;
    border: 1px solid ${(props) => props.theme.colors.grey};
    background-color: ${(props: { theme: any; expanded: boolean }) =>
      props.expanded ? props.theme.colors.lightGrey : props.theme.colors.white};
    box-shadow: ${(props: { expanded: boolean }) =>
      props.expanded
        ? '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
        : 'none'};
    text-transform: none;
    font-family: Vodafone Lt;
    color: ${(props) => props.theme.colors.black};

    expand-right__button {
      border-radius: 0;
      background-color: ${(props: { theme: any; expanded: boolean }) =>
        props.expanded
          ? props.theme.colors.lightGrey
          : props.theme.colors.white};
    }

    .expand-right__title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 6px 8px;
      width: 100%;

      h5 {
        font-size: 18px;
        line-height: 24px;
        margin: 0;

        span {
          color: ${(props) => props.theme.colors.vodafoneRed};
        }
      }

      p {
        font-size: 14px;
        line-height: 16px;
        color: ${(props) => props.theme.colors.shadowGrey};
        margin: 0;
      }

      .expand-right__title__description {
        margin-bottom: 10px;
        font-size: 14px;

        & > * {
          margin-right: 15px;
        }
      }

      .expand-right__title__subtitle {
        justify-content: space-between;
        width: ${(props: { hideExpand?: boolean }) =>
          props.hideExpand ? '50%' : '75%'};
      }

      button {
        border: 0;
        background-color: ${(props: { theme: any; expanded: boolean }) =>
          props.expanded
            ? props.theme.colors.lightGrey
            : props.theme.colors.white};
        padding: 0;
        font-size: 18px;
        line-height: 24px;
        font-family: Vodafone Rg;
        cursor: pointer;

        &:focus {
          outline: 0;
          color: ${(props) => props.theme.colors.vodafoneRed};
        }

        &:hover {
          color: ${(props) => props.theme.colors.vodafoneRed};
        }
      }

      .expand-right__title__row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .expand-right__title__row__date {
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
            width: 20px;
          }

          p {
            color: ${(props) => props.theme.colors.black};
          }
        }
      }
    }

    img {
      -webkit-transition: -webkit-transform 0.2s ease-in-out;
      -ms-transition: -ms-transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out;
      transform: rotate(
        ${(props: { expanded: boolean }) => (props.expanded ? 180 : 0)}deg
      );
    }
  }

  .expand-right__content {
    width: calc(30% - 10px);
    margin: 5px 0 5px 5px;
    padding: 5px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    border: 1px solid ${(props) => props.theme.colors.grey};
    box-shadow:
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    .expand-right__content__details {
      // column-count: 2;

      p {
        font-size: 16px;
        margin: 0;
        padding: 5px;
      }
    }
  }
`;
