import styled from 'styled-components';
import { Accordion } from '@mui/material';

interface StyledDivProps {
  color: string;
}

export default styled(Accordion)<StyledDivProps>`
  margin: 20px 0 !important;
  margin: 0;
  border-radius: 0 !important;

  .accordion--angular {
    margin: 0;
    border-radius: 0 !important;
  }

  p {
    font-size: 18px;
  }

  .row {
    display: flex;
    width: 100%;

    div:nth-child(1) {
      width: 45%;
    }

    div:nth-child(2) {
      width: 30%;
    }

    div:nth-child(3) {
      width: 25%;
    }

    .column--w-50 {
      width: 50% !important;
    }
  }

  .row--justify-end {
    justify-content: flex-end;
  }

  .row--justify-space-between {
    justify-content: space-between;
  }

  .custom-accordion__summary {
    padding: 0 20px 0 0;
    font-family: Vodafone Lt;

    .custom-accordion__summary__title {
      color: ${(props) => props.theme.colors.black};

      h2 {
        font-size: 42px;
        margin: 0;
      }

      p {
        font-size: 22px;
        margin: 10px 0;

        span {
          color: ${(props: { color: string }) => props.color};
        }
      }
    }

    .custom-accordion__summary__entries {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p {
        margin: 0;
      }
    }
  }

  .custom-accordion__details {
    padding: 0 20px 20px 27px;
  }

  .accordion__summary--outlined {
    border: 1px solid ${(props) => props.theme.colors.grey};

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .accordion__details--display-column {
    display: flex;
    flex-direction: column;
  }
`;
