import React, { useState, SyntheticEvent, ChangeEvent } from 'react';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

import ControlledCheckbox from '../../../../../components/ControlledCheckbox';
import ControlledInput from '../../../../../components/ControlledInput';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import ManageIcon from '../../../../../assets/icons/settings.svg';
import environment from '../../../../../environment';
import GenericModal from '../../../../../components/GenericModal';

const SkuGroupBulkChangesModal: React.FC<{ id: string; skuGroup: string }> = ({
  id,
  skuGroup
}) => {
  const [canSell, setCanSell] = useState(false);
  const [forwardOrder, setForwardOrder] = useState(false);
  const [backOrder, setBackOrder] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [releaseDate, setReleaseDate] = useState(null);
  const [minReleaseDate, setMinReleaseDate] = useState(
    moment().add(2, 'days').toDate()
  );
  const [preorderLimit, setPreorderLimit] = useState<number | string>('');
  const [helperText, setHelperText] = useState('');

  const warnings: string[] = [
    'All changes made through this form will be overwritten by the next RMS catalogue update',
    'Changes made through this form apply to all stock pots for all products in the sku group, ' +
      'except for the release date and the preorder limit, which are only updated on MULTICHANL'
  ];

  const handlePreorderLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    const filteredValue: string = event.target.value.replace(/[^0-9]/g, '');
    const limit: number | string =
      filteredValue !== '' ? parseInt(filteredValue, 10) : '';
    setPreorderLimit(limit);
  };

  const clearForm = () => {
    setCanSell(false);
    setForwardOrder(false);
    setBackOrder(false);
    setReleaseDate(null);
    setPreorderLimit('');
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      const data = {
        canSell,
        forwardOrder,
        backOrder,
        releaseDate: releaseDate ? moment(releaseDate).format() : '',
        ...(preorderLimit !== '' ? { preorderLimit } : {})
      };

      try {
        await axios.put(
          `${environment.apiPath}skuGroup/${id}/subinventories`,
          data,
          {
            ...environment.params
          }
        );

        toast.success(`
          Products in group ${skuGroup} successfully scheduled for update.
          Changes may take a few minutes to be applied.
        `);
      } catch (err: AxiosError | any) {
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error(err.message);
        }
      } finally {
        setLoading(false);
        setOpen(false);
        clearForm();
      }
    };

  return (
    <GenericModal
      iconBtn={ManageIcon}
      clearForm={clearForm}
      formTitle='Update Products Settings'
      formSubtitle={<h3>SKU Group: {skuGroup}</h3>}
      formError={dateError}
      warnings={warnings}
      handleSubmit={handleSubmit}
    >
      <CustomDatePicker
        id='release-date'
        value={releaseDate}
        handleChange={(event: any) => {
          setMinReleaseDate(moment().add(1, 'days').toDate());
          setReleaseDate(event);
        }}
        label='Release date &#38; time'
        minDate={minReleaseDate}
        helperText={helperText}
        onError={(error: any) => {
          setDateError(error);
          setHelperText(
            error ? 'Release date has to be set up 24H in advance' : ''
          );
        }}
      />
      <ControlledCheckbox
        checked={canSell}
        handleChange={(event: ChangeEvent<HTMLInputElement>) =>
          setCanSell(event.target.checked)
        }
        label='Can sell'
      />
      <ControlledCheckbox
        checked={forwardOrder}
        handleChange={(event: ChangeEvent<HTMLInputElement>) =>
          setForwardOrder(event.target.checked)
        }
        label='Forward order'
      />
      <ControlledCheckbox
        checked={backOrder}
        handleChange={(event: ChangeEvent<HTMLInputElement>) =>
          setBackOrder(event.target.checked)
        }
        label='Backorder'
      />
      <ControlledInput
        id='preorderLimit'
        label='Preorder limit'
        type='text'
        value={preorderLimit}
        placeholder='Type preorder limit here'
        handleChange={handlePreorderLimitChange}
      />
    </GenericModal>
  );
};

export default SkuGroupBulkChangesModal;
