import styled from 'styled-components';

interface StyledDivProps {
  color: string;
}

export default styled.div<StyledDivProps>`
  background-color: ${(props: { color: string }) => props.color};
  width: 7px;
  height: 140px;
  margin-right: 20px;
`;
